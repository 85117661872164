

// descriptions
import test from "./Project/Components/descriptions/test.json"
import OctoOrganizer from "./Project/Components/descriptions/octo.json"
import laptopstand from "./Project/Components/descriptions/laptopstand.json"
import weatherdisplay from './Project/Components/descriptions/weatherdisplay.json'
import smarttongs from './Project/Components/descriptions/smarttongs.json'



// images
import testimage from "./Project/Components/assets/testimage.png"

import octo1 from "./Project/Components/assets/Octo1.png"
import octo2 from "./Project/Components/assets/Octo2.png"
import octo3 from "./Project/Components/assets/Octo3.png"

import stand1 from "./Project/Components/assets/stand1.jpeg"
import stand2 from "./Project/Components/assets/stand2.jpeg"
import stand3 from "./Project/Components/assets/stand3.jpg"
import stand4 from "./Project/Components/assets/stand4.jpeg"
// import stand5 from "./Project/Components/assets/stand3.jpeg"

import weather1 from "./Project/Components/assets/weather1.jpeg"
import weather2 from "./Project/Components/assets/weather2.jpeg"
import weather3 from "./Project/Components/assets/weather3.jpeg"
import weather4 from "./Project/Components/assets/weather4.jpeg"
import weather5 from "./Project/Components/assets/weather5.jpeg"
import weather6 from "./Project/Components/assets/weather6.jpeg"


import tongs1 from "./Project/Components/assets/tongs1.png"
import tongs2 from "./Project/Components/assets/tongs2.jpeg"
import tongs3 from "./Project/Components/assets/tongs3.png"
import tongs4 from "./Project/Components/assets/tongs4.png"
import tongs5 from "./Project/Components/assets/tongs5.png"
import tongs6 from "./Project/Components/assets/tongs6.png"
import tongs7 from "./Project/Components/assets/tongs7.png"
import tongs8 from "./Project/Components/assets/tongs8.png"
import tongs9 from "./Project/Components/assets/tongs9.png"


const all_objects = [
    {
        name: "Test",
        description: test,
        imgs: [testimage, testimage],
    },
    {
        name: "OctoOrganizer",
        description: OctoOrganizer,
        imgs: [octo1, octo2, octo3],
    },
    {
        name: "LaptopStand",
        description: laptopstand,
        imgs: [stand1, stand2, stand3, stand4],
    },
    {
        name: "WeatherDisplay",
        description: weatherdisplay,
        imgs: [weather1, weather2, weather3, weather4, weather5, weather6]
    },
    {
        name: "SmartTongs",
        description: smarttongs,
        imgs: [tongs1, tongs2, tongs3, tongs4, tongs5, tongs6, tongs7, tongs8, tongs9]
    },
    {
        name: "misc",
        description: "none",
        imgs: [octo1, stand1, weather6, tongs9]
    }
]

export function get_object(name) {
    let returnvalue;
    all_objects.forEach((obj) => {
      if (obj.name == name) {
        returnvalue = obj;
      }
    });
    return returnvalue;
  }

export default all_objects