import ContentRow from "../Project/Components/ContentRow";
import Topbar from "../Project/Components/Topbar";
import Template from "../Project/Template";
import { get_object } from "../exporter";
import "./styles/tongsStyles.css"

let obj = get_object("SmartTongs")
export default function SmartTongs() {
    let email = "bmlrb2xhaS53b2VnZXJAZ21haWwuY29t";
    return (

        <>
            <Topbar></Topbar>
            <h2 className='Project-title'>Smart Tongs</h2>
            <div class="row-content-container">

                {/* Descriptions and pictures of the */}
                <div className={"photo-row"} style={{marginBottom: "-5em"}}>
                    <div className="img-card" style={{ flex:"0 0 75%"}}>
                        <img src={obj.imgs[8]}></img>
                    </div>
                </div>
                <ContentRow description={obj.description[0]}></ContentRow>
                <ContentRow img={obj.imgs[0]} description={obj.description[1]}></ContentRow>
                <ContentRow img={obj.imgs[1]} imgSide={"left"} description={obj.description[2]}></ContentRow>
                <ContentRow listDescBasis="100em" description={obj.description[3]}></ContentRow>
                <ContentRow img={obj.imgs[2]} description={obj.description[4]}></ContentRow>
                <ContentRow description={obj.description[5]}></ContentRow>

                {/* Below is used for the gallery of the  */}
                <div className={"photo-row"} style={{marginTop: "-5em"}}>
                    <div className="img-card" style={{ flex:"0 0 75%"}}>
                        <img src={obj.imgs[3]}></img>
                    </div>
                </div>
                <div className={"photo-row"} style={{marginTop: "-5em"}}>
                    <div className="img-card" style={{ flex:"0 0 75%"}}>
                        <img src={obj.imgs[5]}></img>
                    </div>
                </div>
                <div className="photo-row" style={{marginTop: "-5em"}}>
                    <div className="img-card" style={{ flex:"0 0 28%", height:"17em" }}>
                            <img src={obj.imgs[4]}></img>
                    </div>
                    <div className="img-card" style={{ flex:"0 0 28%", height:"17em" }}>
                        <img src={obj.imgs[6]}></img>
                    </div>
                    <div className="img-card" style={{ flex:"0 0 28%", height:"17em" }}>
                        <img src={obj.imgs[7]}></img>
                    </div>
                </div>

                <div className="tongs-footer">
                    <p>For more detailed information about our project, see our project report below</p>
                    <button onClick={() => {window.open("https://docs.google.com/document/d/16iBYbr_WJYmgQRuKw6l6fgpprr3iihjjPfZF4ZCpw8s/edit?tab=t.0")}} target="_blank">T3am Thr33</button>
                    <p>If you have any questions about this project, please feel free to <button onClick={() => {window.open("mailto:" + atob(email))}}>Contact Me</button> about them</p>
                </div>
            </div>  
        </>
    )
}