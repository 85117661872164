import "./Topbar.css"


export default function Topbar({ name }) {
    let email = "bmlrb2xhaS53b2VnZXJAZ21haWwuY29t";
    return (
        <>
            <div className='home-header'>
                <a href="/home/"><h1>Nikolai Woeger - Portfolio</h1></a>
                <button onClick={() => {window.open("mailto:" + atob(email))}}>Contact Me</button>
            </div>
        </>
    )
}