import './NewHome.css'
import { get_object } from "../exporter.js"
import Topbar from '../Project/Components/Topbar.js'

let octo = get_object("misc").imgs[0]
let laptop = get_object("misc").imgs[1]
let weather = get_object("misc").imgs[2]
let tongs = get_object("misc").imgs[3]

export default function NewHome() {
    let email = "bmlrb2xhaS53b2VnZXJAZ21haWwuY29t";

    return (
        <>
            <Topbar></Topbar>
            <div className='home-body'>
                <div className='home-outside-card' style={{ marginTop: "13em" }}>
                    <div className='home-card' style={{ width: '50em' }}>
                        <h2>Nikolai Woeger</h2>
                        <p>Electrical Engineer</p>
                        <p>Python, C/C++, Javascript</p>
                        <p>Web Development - Frontend</p>
                    </div>
                </div>
                <div className='home-outside-card' style={{ marginTop: "18em" }}>
                    <div className='home-card' id='home-about-me' style={{ width: "65em" }}>
                        <h3 style={{ fontWeight: "bold" }}>Who am I?</h3>
                        <p>Hello and welcome to my portfolio</p>
                        <p style={{ zIndex: 2}}>I am currently a student at the University of Colorado Boulder studying  
                            <spam style = {{fontWeight: "bolder", fontStyle: "italic"}}> Electrical and Computer Engineering</spam>. 
                            I have experience in a variety of different fields. I am a passionate programmer, creator, and engineer, 
                            and am looking for various applications to match my skillsets. 
                            <br></br>
                            <br></br>
                            Below, you can find some of the things that I have been working on. If you find anything that interests you, 
                            feel free to <a style={{fontWeight: "bold" }} href={"mailto:" + atob(email)}> email me </a> with any further questions, 
                            as this portfolio is simply meant to briefly present my experience.</p>
                    </div>
                </div>
                <div className='home-outside-card' style={{ marginTop: "20em" }}>
                    <div className='home-card' style={{ width: "70em" }}>
                        <h3 style={{ fontWeight: "bold", paddingBottom: "1em" }}>My Projects</h3>

                        <div className="home-proj-row">
                            <button onClick={() => {window.open("/octoorganizer/", '_self')}} >Octo Organizer</button>
                            <div className='img-card' style={{ flex: '0 0 15%' }}>
                                <img src={octo} style={{ width: "100%", padding: "0" }}></img>
                            </div>
                            <p>A planner website meant for students to be able to keep track of their assignments and limit the amount of tabs that they need open.</p>
                        </div>

                        <div className="home-proj-row">
                            <button onClick={() => {window.open("/laptopstand/", '_self')}}>Laptop Stand</button>
                            <div className='img-card' style={{ flex: '0 0 15%' }}>
                                <img src={laptop} style={{ width: "100%", padding: "0" }}></img>
                            </div>
                            <p>A laptop stand meant to help collge students conserve space and increase productivity <br></br> This was a project for IB Design Technology.</p>
                        </div>

                        <div className="home-proj-row">
                            <button onClick={() => {window.open("/weatherdisplay/", '_self')}}>Weather Display</button>
                            <div className='img-card' style={{ flex: '0 0 15%' }}>
                                <img src={weather} style={{ width: "100%", padding: "0" }}></img>
                            </div>
                            <p>A small device that reads weather data from an API call and converts it to a simple, human readable interface.</p>
                        </div>

                        <div className="home-proj-row">
                            <button onClick={() => {window.open("/smarttongs/", '_self')}}>Smart Tongs</button>
                            <div className='img-card' style={{ flex: '0 0 15%' }}>
                                <img src={tongs} style={{ width: "100%", padding: "0" }}></img>
                            </div>
                            <p>Tongs that are meant to limit food waste in dining halls by communicating typical food serving sizes of a given food</p>
                        </div>

                    </div>
                </div>

                
            </div>
        </>
    )
}